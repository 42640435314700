import React from "react"
import { Link } from "gatsby-plugin-react-i18next"
import HeadingUnderline from "./headingUnderline"


const LearningUnitCard = ({ lu }) => {
  const { title, teaching_time_minutes, duration_repeat, path } = lu
  const { phase, learning_scenario } = lu.relationships
  const formats = lu.relationships.format.map(term => term.name)

  return <Link to={path.alias} className={`lu-card pane ${phase ? `phase-${phase.drupal_id}` : "phase-none"}`}>
    <div className="top pad-s">
      <div className="lu-card-header">
        { phase && <span className="lu-card-phase">{ phase.name }</span> }
        <div>
          { learning_scenario && <>{ learning_scenario.title }</> }
          <HeadingUnderline title={ title } titleWeight="h2" />
        </div>
      </div>
      
      <em>
        { formats.join(" - ") }
        { teaching_time_minutes && <span className="lu-time"> (
          { duration_repeat !== 1 && duration_repeat !== null ? `${duration_repeat} × ` : "" }
          { teaching_time_minutes } min.)
        </span> }
      </em>
    </div>
  </Link>
}

export default LearningUnitCard
