import React from "react"
import { Link, useTranslation } from "gatsby-plugin-react-i18next"

import { makeAgeRangeString, makeTimeString } from "../util/format"

import HeadingUnderline from "./headingUnderline"


const LearningScenarioCard = ({ ls, filtered }) => {
  const { t } = useTranslation()

  const image = ls.image && ls.image[0] ? { ...ls.image[0], ...ls.relationships.image[0] } : null
  const minutesTotal = ls.relationships.learning_units.reduce((agg, lu) => {
    lu = lu?.relationships.learning_unit_reference
    return agg + (lu?.teaching_time_minutes || 0) * (lu?.duration_repeat || 1)
  }, 0)

  return <Link key={ls.id} to={ls?.path?.alias} language={ls?.path.langcode} className="learning-scenario">
    <div>
      <div className="offset-up">
        <div className="image-ratio image-ratio-2-1">
          { image && <img src={image.styles?.medium} alt={image.alt} width={image.width} height={image.height} data-object-fit="cover" /> }
          <div className="image-label">
            { filtered && <span>{ t("SEARCH-LUS_MATCH", { count1: ls._cnt, count2: ls.relationships.learning_units.length }) }</span> }
            { !filtered && <span>{ t("SEARCH-LU", { count: ls.relationships.learning_units.length }) }</span> }
          </div>
        </div>
      </div>
      <div className="text">
        <HeadingUnderline title={ ls.title } titleWeight="h2" />
        <p>{ ls.subtitle }</p>
      </div>
    </div>
    <div className="meta">
      { makeAgeRangeString(ls.minimum_age, ls.maximum_age) } { t("YRS") }
      <em>{ makeTimeString(minutesTotal, t) }</em>
    </div>
  </Link>
}

export default LearningScenarioCard
