import React, { useContext } from "react"
import { graphql } from "gatsby"
import { MyContext } from "../context/my-context"
import { useI18next } from "gatsby-plugin-react-i18next"
import { remove } from "../context/records-reducer"

import Seo from "../parts/seo"
import Layout from "../parts/layout"
import Row from "../parts/row"
import HeadingUnderline from "../parts/headingUnderline"

import LearningScenarioCard from "../parts/learningScenarioCard"
import LearningUnitCard from "../parts/learningUnitCard"


const MyPage = ({ pageContext }) => {
  const { t } = useI18next()
  const { state, dispatch } = useContext(MyContext)
  const learningUnits = pageContext.learningUnits.allNodeLearningUnit
  const learningScenarios = pageContext.learningScenarios.allNodeLearningScenario

  const title = t("FAVOURITES-TITLE")

  const favouriteLS = state?.favourites.filter(rec => rec.type === "ls") || []
  const favouriteLU = state?.favourites.filter(rec => rec.type === "lu") || []


  return (
    <Layout>
      <Seo title={ title } />

      <Row>
        <HeadingUnderline title={ title } classes={["center"]} />
        <p className="center">{ t("FAVOURITES-INTRODUCTION") }</p>
      </Row>


      <Row classes={["flow"]}>
        <h2>{ t("FAVOURITES-LS-TITLE") }</h2>
        { favouriteLS.length === 0 && <p>{ t("FAVOURITES-LS-NONE") }</p> }
        { favouriteLS.length !== 0 && <div className="fr-columns one-third flow-2x">
          { favouriteLS.map( rec => {
            const favo = learningScenarios.nodes.find(ls => ls.drupal_id === rec.id) || {}

            return <div key={favo.id}>
              <LearningScenarioCard ls={ favo } />
              <button className="icon-button" onClick={() => remove(dispatch.favourites, rec.id) }>× { t("FAVOURITES-REMOVE") }</button>
            </div>
          }) }
        </div> }
      </Row>

      <Row classes={["flow"]}>
        <h2>{ t("FAVOURITES-LU-TITLE") }</h2>
        { favouriteLU.length === 0 && <p>{ t("FAVOURITES-LU-NONE") }</p> }
        { favouriteLU.length !== 0 && <div className="fr-columns half flow-2x">
          { favouriteLU.map(rec => {
            const favo = learningUnits.nodes.find(lu => lu.drupal_id === rec.id) || []

            return <div key={rec.id}>
              <LearningUnitCard lu={ favo } />
              <button className="icon-button" onClick={() => remove(dispatch.favourites, rec.id) }>× { t("FAVOURITES-REMOVE") }</button>
            </div>
          }) }
        </div> }
      </Row>

    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {in: [$language, "pt", "en"]}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default MyPage
